import { useEffect, useState } from "react";
import Header from "./Header";
import axios from "axios";
import { Link } from "react-router-dom";
import UpdatePro from "./UpdatePro";

function ProductView(){
    const [List,SetItem]=useState([]);
    useEffect(()=>{
        async function data_fetch(){
        await fetch("http://localhost/vishwas/backend/product_list.php")
        .then(res =>res.json())
        .then((result)=>{SetItem(result)})
        
        }
        data_fetch();
    },[])

    function delete_item(id){
        const new_list=List.filter((lists)=> lists.id !== id);
        SetItem(new_list);
        const url="http://localhost/vishwas/backend/deleelist.php";
        const send_form=document.getElementById('delete_form');
        let deleteData = new FormData(send_form);
        deleteData.append('delete_id',id);
        axios.post(url,deleteData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(Response=>console.log(Response.data)).catch(error=>alert(error));

    }
    return(
        <>
        <div className="upgradepop">
          
        </div>
        <form method="post" id="delete_form">
            <input type="hidden" name="delte_id"/>
        </form>
        <section id="content-section">
        <div className="col-sm-12 col-md-12 col-lg-12 content-box-list">
            
        <table class="table table-striped table-image">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Product-Name</th>
      <th scope="col">Product-Image</th>
      <th scope="col">Product-Description</th>
      <th scope="col">Product-Category</th>
      <th scope="col">Product-Price</th>
      <th scope="col">Edit</th>
      <th scope="col">Delete</th>
    </tr>
  </thead>
  <tbody>
    
    {List !="" ? List.map((item)=>(
        <tr>
      <th scope="row" key={item.id}>{item.id}</th>
      <td>{item.product_name}</td>
      <td><img src={item.product_image} aria-hidden alt="image"/>
           {item.product_image2 != 0?<img src={item.product_image2} aria-hidden alt={"image"}/>:null}
           {item.product_image3 != 0?<img src={item.product_image3} aria-hidden alt={"image"}/>:null}
           {item.product_image4 != 0?<img src={item.product_image4} aria-hidden alt={"image"}/>:null}</td>
      <td>{item.product_desc.substring(0,15)+"..."}</td>
      <td>{item.product_cat}</td>
      <td>{item.product_price}</td>
      <td><Link to={"/admin/update?id="+item.id}><button className={"btn btn-primary"}>✏</button></Link></td>
      <td><button onClick={()=>delete_item(item.id)} className={"btn btn-danger"}>❌</button></td>
    </tr>
    )
):<tr><td style={{textAlign: "center", fontWeight:"600"}} colSpan={100}>#No Data Available</td></tr>}
    

  </tbody>
</table>

        </div>
        </section>
        </>
        );
}
export default ProductView;