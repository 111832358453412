import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function ViewCategory(){
    const [Formerror,Setformerror]=useState({});
    const [isSubmit,setIsSubmit]=useState(false);
    const [List,SetItem]=useState([]);
    const [UpdateList,SetUpdateList]=useState({
      category_id:"",
      category_name:"",
      category_image:""
    });
    const [ActiveUpdate,SetUpdate]=useState('none');
    useEffect(()=>{
        async function data_fetch(){
        await fetch("http://localhost/vishwas/backend/category/viewcategory.php")
        .then(res =>res.json())
        .then((result)=>{SetItem(result)})
        
        }
        data_fetch();
    },[insert_product])


    function update_item(id){
      if(ActiveUpdate === "none"){
        SetUpdate('block');
      document.documentElement.style.setProperty('--category-update',"block");
      const update_itm=List.filter((lists)=> lists.id === id);
      SetUpdateList({
        category_id:update_itm[0].id,
        category_name:update_itm[0].category_name,
        category_image:update_itm[0].category_image
      })
    }else{
      SetUpdate('none');
      document.documentElement.style.setProperty('--category-update',"none");
    }
    }

    function insert_product(){
      const form =document.getElementById('formed');
      const url="http://localhost/vishwas/backend/category/updatecat.php?id="+UpdateList.category_id;
      let fData= new FormData(form);
      
      fData.append('category_name',UpdateList.category_name);
      fData.append('category_image',fData.get('category_image'));
      
  axios.post(url,fData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }).then(Response=>alert(Response.data),update_item(UpdateList.category_id)).catch(error=>alert(error));



  }


    function handlesubmit(e){
      e.preventDefault();
      // const form =document.getElementById('formed');
      // const url="http://localhost/vishwas/backend/update_product.php?id="+value;
      // let fData= new FormData(form);
      Setformerror(validate(UpdateList,e));
      if(UpdateList.category_name && UpdateList.category_image){
        setIsSubmit(true);
        }
      
    }

    useEffect(()=>{
    
      if(Object.keys(Formerror).length === 0 && isSubmit){
          insert_product();
      }
    },[Formerror]);

    const validate = (values,e)=>{
      const errors = {};
      if(!values.category_name){
         errors.category_name="Category Name Required!";
      }
      if(!values.category_image){
         errors.category_image = "Category Image Required";
      }
      return errors;
      }

      function handleform(e){
        SetUpdateList({...UpdateList,[e.target.name]:e.target.value});
        
       // SetValidation({...Validation,[e.target.name]:e.target.value});
        //console.log(Validation); 
    
       }

    function cancel_update(){
      SetUpdate('none');
      SetUpdateList({
        category_id:"",
        category_name:"",
        category_image:""
      })
      
      document.documentElement.style.setProperty('--category-update',"none");

    }

    function delete_item(id){
        const new_list=List.filter((lists)=> lists.id !== id);
        SetItem(new_list);
        const url="http://localhost/vishwas/backend/category/delete.php";
        const send_form=document.getElementById('delete_form');
        let deleteData = new FormData(send_form);
        deleteData.append('delete_id',id);
        axios.post(url,deleteData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(Response=>console.log(Response.data)).catch(error=>alert(error));

    }
    return(
        <>
        <div className="upgradepop">
        
        <form method="post" onChange={(e)=>handleform(e)} onSubmit={(e)=>handlesubmit(e)} encType="multipart/form-data" id="formed">
                  <div className="form-box">
              <div className="form-heading">
                  <h6>Category-Name</h6>
              </div>
              <div className="form-input">
                  <input type="text" name="category_name" defaultValue={UpdateList.category_name}  className="form-control"/>
                  <span className="form_error">{Formerror.category_name}</span>
              </div>
              </div>
              <br/>
              <div className="form-box">
       <div className="form-heading">
           <h6>Category-Image</h6>
       </div>
       <div className="form-input">
           <input type="file" name="category_image"   className="form-control"/>
           <span className="form_error">{Formerror.category_image}</span>
       </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-12 form-btns">
      <button onClick={()=>cancel_update()} className="btn btn-danger form-btnss" >Cancel</button>
      &nbsp;
      <button type="submit" className="btn btn-primary form-btnss" >Save</button>
      </div>
          </form>

        </div>
        <form method="post" id="delete_form">
            <input type="hidden" name="delte_id"/>
        </form>
        <section id="content-section">
        <div className="col-sm-12 col-md-12 col-lg-12 content-box-list">
            
        <table class="table table-striped table-image">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Category-Name</th>
      <th scope="col">Category-Image</th>
      <th scope="col">Edit</th>
      <th scope="col">Delete</th>   
    </tr>
  </thead>
  <tbody>
    
    {List !="" ? List.map((item)=>(
      
        <tr>
      <th scope="row" key={item.id}>{item.id}</th>
      <td>{item.category_name}</td>
      <td><img src={"category/"+item.category_image} aria-hidden alt="image"/></td>
      <td><button onClick={()=>update_item(item.id)} className={"btn btn-primary"}>✏</button></td>
      <td><button onClick={()=>delete_item(item.id)} className={"btn btn-danger"}>❌</button></td>
    </tr>
    )
):<tr><td style={{textAlign: "center", fontWeight:"600"}} colSpan={100}>#No Data Available</td></tr>}
    

  </tbody>
</table>

        </div>
        </section>
        </>
        );
}
export default ViewCategory;