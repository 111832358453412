import { useState } from "react";
import Product from "./Product";
import ProductView from "./ProductView";
import UpdatePro from "./UpdatePro";
import { Link } from "react-router-dom";
import AddCategory from "./category/AddCategory";
import ViewCategory from "./category/ViewCategory";
import AddTestimonial from "./testimonials/AddTestimonial";
import ViewTestimonial from "./testimonials/ViewTestimonial";
import AddUsers from "./users/AddUsers";
import ViewUsers from "./testimonials/ViewTestimonial";


function Header(){
    
    const [LeftGrid,SetLeft]=useState('-200px');
    const [Productnav,SetProductNav]=useState('none');
    const [Category,SetCategory]=useState('none');
    const [Testimonial,SetTestimonial]=useState('none');
    const [UserNav,SetUserNav]=useState('none');
    const [Active,SetActive]=useState("productview");
    function gridshow(LeftGrid){
        if(LeftGrid === '-200px'){
            SetLeft('0px');
        }
        else SetLeft('-200px');
        document.documentElement.style.setProperty('--grid-margin',LeftGrid);
    }
    function productshow(Productnav){
        if(Productnav === 'none'){
            
            SetProductNav('block');
        }
        else SetProductNav('none');
        document.documentElement.style.setProperty('--product-navs',Productnav);
    }
    function usershow(UserNav){
        if(UserNav === 'none'){
            
            SetUserNav('block');
        }
        else SetUserNav('none');
        document.documentElement.style.setProperty('--users-navs',UserNav);
    }
    function categoryshow(category){
        if(category === 'none'){
            
            SetCategory('block');
        }
        else SetCategory('none');
        document.documentElement.style.setProperty('--category-navs',category);
    }
    function testshow(Testimonial){
        if(Testimonial === 'none'){
            
            SetTestimonial('block');
        }
        else SetTestimonial('none');
        document.documentElement.style.setProperty('--testimonial-navs',Testimonial);
    }
    
    
return(
    <>
    <div className="col-sm-12 col-md-12 col-lg-12 boxing">
    <section id="menu">
        <div className="logo">
            <img src="http://localhost:3000/logo.png" alt="logo"/>
            
        </div>

        <div className="items">
            <li><i className="fa fa-dashboard"></i>Dashboard</li>
            <li onClick={()=>categoryshow(Category)}><i className="fa fa-user" ></i>Category</li>
            <div className="inside-linkc" id="hiding-link">
            <li onClick={()=>SetActive("category")}>Category Add</li>
            <li onClick={()=>SetActive("categoryview")}>Category View</li>
            </div>
            <li onClick={()=>productshow(Productnav)}><i className="fa fa-user" ></i>Product</li>
            <div className="inside-link" id="hiding-link">
            <li onClick={()=>SetActive("product")}>Product Add</li>
            <li onClick={()=>SetActive("productview")}>Product View</li>
            </div>
            <li onClick={()=>testshow(Testimonial)}><i className="fa fa-user" ></i>Testimonials</li>
            <div className="inside-linkt" id="hiding-link">
            <li onClick={()=>SetActive("testimonial")}>Testimonial Add</li>
            <li onClick={()=>SetActive("testimonialview")}>Testimonial View</li>
            </div>
            <li onClick={()=>usershow(UserNav)}><i className="fa fa-user" ></i>Users</li>
            <div className="inside-linku" id="hiding-link">
            <li onClick={()=>SetActive("users")}>User Add</li>
            <li onClick={()=>SetActive("usersview")}>User View</li>
            </div>
        </div>
        
    </section>
    <section id="interface">
    <div className="navigation">
        <div className="n1">
            <div className="search">
                <i className="fa fa-list grid-hide" onClick={()=>gridshow(LeftGrid)}></i>
                <i className="fa fa-search"></i>
                <input type="text" placeholder="Search"/>
            </div>
        </div>
        <div className="profile">
            <i className="fa fa-bell"></i>
            <i className="fa fa-user"></i>
        </div>
    </div>
    <div className="clear"></div>
    {Active === "product" && <Product/>}
    {Active === "productview" && <ProductView/>}
    {Active === "category" && <AddCategory/>}
    {Active === "categoryview" && <ViewCategory/>}
    {Active === "testimonial" && <AddTestimonial/>}
    {Active === "testimonialview" && <ViewTestimonial/>}
    {Active === "users" && <AddUsers/>}
    {Active === "usersview" && <ViewUsers/>}
    
    </section>
    
    
    </div>
    </>
);
}
export default Header;