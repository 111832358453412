import axios from "axios";
import { useEffect, useState } from "react";

function AddUsers(){

    const [FormD,SetFormD]=useState([{
    pusersname:"",
    pemail:"",
    ppassword:""
}]);
const [Model,SetModel]=useState('none');
const [Formerror,Setformerror]=useState({});
const [isSubmit,setIsSubmit]=useState(false);

function model_box_hide(){
    document.documentElement.style.setProperty('--entry-record',"none")    
}
function model_box(data){
document.documentElement.style.setProperty('--entry-record',"Block")
setTimeout(model_box_hide, 1000);
}

  function insert_product(){
      const form =document.getElementById('formed');
      const url="http://localhost/vishwas/backend/users/adduser.php";
      let fData= new FormData(form);    
  fData.append('user_name',FormD.pusername);
  fData.append('user_email',FormD.pemail);
  fData.append('user_password',FormD.ppassword);
   
   axios.post(url,fData, {
     headers: {
       "Content-Type": "multipart/form-data",
     },
   }).then(Response=>model_box(Response.data)).catch(error=>alert(error));

  }



  

  function handlesubmit(e){
    e.preventDefault();
    Setformerror(validate(FormD,e));
    if(FormD.pusername && FormD.pemail && FormD.ppassword){
    setIsSubmit(true);
    }
    
  }
  useEffect(()=>{
    if(Object.keys(Formerror).length === 0 && isSubmit){
        
        insert_product();
    }
  },[Formerror]);
  
  const validate = (values,e)=>{
     const errors = {};
     const regex= "^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9.-]+$";
     if(!values.pusername){
        errors.pusername="User Name Required!";
     }
     if(!values.pemail){
      errors.pemail = "Email  Required";
   }
   if(!values.ppassword){
    errors.ppassword = "Password Description Required";
 }
     return errors;
     }


  

   function handleform(e){
    
    SetFormD({...FormD,[e.target.name]:e.target.value});
    
   // SetValidation({...Validation,[e.target.name]:e.target.value});
    //console.log(Validation); 

   }
    return(
    <section id="content-section">
        <div className="popup">
            <img src={"tick.png"} alt="img"/>
            <h2>New Entry Recorded Successfully</h2>
        </div>
    <div className="col-sm-12 col-md-12 col-lg-12 content-box">
     <form method="post" onChange={(e)=>handleform(e)} onSubmit={(e)=>handlesubmit(e)} encType="multipart/form-data" id="formed">

      <div className="form-box">
       <div className="form-heading">
           <h6>User-Name</h6>
       </div>
       <div className="form-input">
           <input type="text" name="pusername" className="form-control"/>
           <span className="form_error">{Formerror.pusername}</span>
       </div>
      </div>

      <div className="form-box">
       <div className="form-heading">
           <h6>User-Email</h6>
       </div>
       <div className="form-input">
           <input type="email" name="pemail" className="form-control"/>
           <span className="form_error">{Formerror.pemail}</span>
       </div>
      </div>

      <div className="form-box">
       <div className="form-heading">
           <h6>User-Password</h6>
       </div>
       <div className="form-input">
           <input type="text" name="ppassword" className="form-control"/>
           <span className="form_error">{Formerror.ppassword}</span>
       </div>
      </div>

      
     <div className="col-sm-12 col-md-12 col-lg-12 form-btns">
      <button type="submit" className="btn btn-primary form-btnss" >Save</button>
      
      </div>

      </form>
      </div>
   </section>
    );
}
export default AddUsers;