import axios from "axios";
import { useEffect, useState } from "react";

function AddTestimonial(){

    const [FormD,SetFormD]=useState([{
    ptestimonial:"",
    pimage:"",
    pdesc:""
}]);

const [Formerror,Setformerror]=useState({});
const [isSubmit,setIsSubmit]=useState(false);


  function insert_product(){
      const form =document.getElementById('formed');
      const url="http://localhost/vishwas/backend/testimonial/addtestimonial.php";
      let fData= new FormData(form);    
  fData.append('testimonial_name',FormD.ptestimonial);
  fData.append('product_image',fData.get('pimage'));
  fData.append('testimonial_desc',FormD.pdesc);
   
   axios.post(url,fData, {
     headers: {
       "Content-Type": "multipart/form-data",
     },
   }).then(Response=>alert(Response.data)).catch(error=>alert(error));

  }



  

  function handlesubmit(e){
    e.preventDefault();
    Setformerror(validate(FormD,e));
    if(FormD.ptestimonial && FormD.pimage && FormD.pdesc){
    setIsSubmit(true);
    }
    
  }
  useEffect(()=>{
    if(Object.keys(Formerror).length === 0 && isSubmit){
        
        insert_product();
    }
  },[Formerror]);
  
  const validate = (values,e)=>{
     const errors = {};
     const regex= "^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9.-]+$";
     if(!values.ptestimonial){
        errors.category="Testimonial Name Required!";
     }
     if(!values.pimage){
      errors.pimage = "Testimonial Image Required";
   }
   if(!values.pdesc){
    errors.pdesc = "Testimonial Description Required";
 }
     return errors;
     }


  

   function handleform(e){
    
    SetFormD({...FormD,[e.target.name]:e.target.value});
    
   // SetValidation({...Validation,[e.target.name]:e.target.value});
    //console.log(Validation); 

   }
    return(
    <section id="content-section">
    <div className="col-sm-12 col-md-12 col-lg-12 content-box">
       
     <form method="post" onChange={(e)=>handleform(e)} onSubmit={(e)=>handlesubmit(e)} encType="multipart/form-data" id="formed">

      <div className="form-box">
       <div className="form-heading">
           <h6>Testimonial-Name</h6>
       </div>
       <div className="form-input">
           <input type="text" name="ptestimonial" className="form-control"/>
           <span className="form_error">{Formerror.category}</span>
       </div>
      </div>

      <div className="form-box">
      <div className="form-heading">
           <h6>Testimonial-Image</h6>
       </div>
       <div className="form-input" id="image-box" >
           <div>
           <input type="file"  name="pimage"  id="first_file" accept="image/*"/>
           <span className="form_error">{Formerror.pimage}</span>
           </div>

       </div>
      </div>

      <div className="form-box-product">
      <div className="form-heading">
           <h6>Testimonial-Description</h6>
       </div>
       <div className="form-input">
           <input type="text-area"  className="form-control" name="pdesc"/>
           <span   className="form_error">{Formerror.pdesc}</span>
       </div>
      </div>

      
     <div className="col-sm-12 col-md-12 col-lg-12 form-btns">
      <button type="submit" className="btn btn-primary form-btnss" >Save</button>
      
      </div>

      </form>
      </div>
   </section>
    );
}
export default AddTestimonial;