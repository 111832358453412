import axios from "axios";
import { useEffect, useState } from "react";

function Product(){

    const [FormD,SetFormD]=useState([{
    pname:"",
    pprice:"",
    pcategory:"",
    pdesc:"",
    pimage:""


}]);

const [Formerror,Setformerror]=useState({});
const [isSubmit,setIsSubmit]=useState(false);

const [Validation,SetValidation]=useState({
    pname:0,
    pprice2:0,
    pimage:0,
    pcategory:0,
    pdesc:0


});

function image_ext(img){
    
    const ext= img.split('.').pop();
    if(ext =="jpg" || ext =="JPG" || ext =="png" || ext =="PNG" )
    {
        return{ 
        value_of_usestate:1,
        value_of_span:""};
    }
    else{
        return{
        value_of_usestate:0,
        value_of_span:"Extension not allowed"}
        ;}
     
}

//     function form_test(formds,form){
//         const pname=formds.get('pname');
//         const pimage=formds.get('pimage[]');
//         const pimage2=formds.get('pimage2[]');
//         const pimage3=formds.get('pimage3[]');
//         const pimage4=formds.get('pimage4[]');
//         const pprice2=formds.get('pprice2');
//         const pcategory=formds.get('pcategory');
//         const pdesc=formds.get('pdesc');
        
        
    
//         if(pname === ""){
//         SetValidation(0);
//         document.getElementById("pname_error").innerHTML="name required";
//         }
//         else{SetValidation({pname:1});document.getElementById("pname_error").innerHTML="";}

//         if(pprice2 === ""){
//             SetValidation(0);
//             document.getElementById("pprice2_error").innerHTML="price required";
//         }
//         else{SetValidation(1);document.getElementById("pprice2_error").innerHTML="";}

//         if(pimage['name'] == ""){
//             SetValidation(0);
//             document.getElementById("pimage_error").innerHTML="image required";
//         }else{
//             const image_checker=image_ext(pimage['name']);
            
//             SetValidation(image_checker.value_of_usestate);document.getElementById("pimage_error").innerHTML=image_checker.value_of_span;}
        
//         if(formds.get('pimage2[]')){
//             if(pimage2['name'] == ""){
//                 SetValidation(0);
//                 document.getElementById("pimage_error2").innerHTML="image required";
//             }else{
//                 const image_checker=image_ext(pimage2['name']);
//                 //console.log(image_checker.value_of_span);
//                 SetValidation(image_checker.value_of_usestate);document.getElementById("pimage_error2").innerHTML=image_checker.value_of_span;}
//             }

//         if(formds.get('pimage3[]')){
//             if(pimage3['name'] == ""){
//                 SetValidation(0);
//                 document.getElementById("pimage_error3").innerHTML="image required";
//             }else{
//                 const image_checker=image_ext(pimage3['name']);
                
//                 SetValidation(image_checker.value_of_usestate);document.getElementById("pimage_error3").innerHTML=image_checker.value_of_span;}
//             }
    
//             if(formds.get('pimage4[]')){
//                 if(pimage4['name'] == ""){
//                     SetValidation(0);
//                     document.getElementById("pimage_error4").innerHTML="image required";
//                 }else{
//                     const image_checker=image_ext(pimage4['name']);
//                     SetValidation(image_checker.value_of_usestate);document.getElementById("pimage_error4").innerHTML=image_checker.value_of_span;}
//                 }

//     if(pcategory =="false"){
//         SetValidation(0);
//         document.getElementById("pcategory_error").innerHTML="category required";
//     }else{SetValidation(1);document.getElementById("pcategory_error").innerHTML="";}  
    
//     if(pdesc === ""){
//         SetValidation(0);
//         document.getElementById("pdesc_error").innerHTML="description required";
//     }  
//     else{SetValidation(1);document.getElementById("pdesc_error").innerHTML="";}
 
// }


  function insert_product(){
      const form =document.getElementById('formed');
      const url="http://localhost/vishwas/backend/insert.php";
      let fData= new FormData(form);
//    //const form_validator=form_test(fData,form);
//    console.log(Validation);
//    //console.log(Validation);


  fData.append('product_name',FormD.pname);
   fData.append('product_image',fData.get('pimage'));
   fData.append('product_image2',fData.get('pimage2'));
   fData.append('product_image3',fData.get('pimage3'));
   fData.append('product_image4',fData.get('pimage4'));
   fData.append('product_price',FormD.pprice2);
   fData.append('product_cat',FormD.pcategory);
   fData.append('product_desc',FormD.pdesc);
   
   axios.post(url,fData, {
     headers: {
       "Content-Type": "multipart/form-data",
     },
   }).then(Response=>alert(Response.data)).catch(error=>alert(error));

  }

  let increment_perm=1;

  function deletes(e){
    const removed=document.getElementById(e.target.parentNode.id);
    if(increment_perm >= 0){
        increment_perm--;
    console.log("remove"+increment_perm);
    removed.remove();
    }
  }

  

  function add_image(){
        
    let select=document.getElementById('image-box');
    
    let multi=document.createElement('input');
    multi.type='File';
    multi.className='flex';
    
    
    
    let del=document.createElement('i');
    del.className='fa fa-remove';
    del.onclick=(e)=>deletes(e);


    let spd=document.createElement('span');
    spd.className='form_error';
    

    const flex=document.createElement('div');
    flex.className='flexship';
    flex.id='FileUplaod'+increment_perm;

     if(increment_perm <= 3){
        increment_perm++;
        multi.name='pimage'+increment_perm;
        spd.id='pimage_error'+increment_perm;
        //spd.innerHTML='{errors.pimage}'+increment_perm;
         select.appendChild(flex);
         flex.appendChild(del);
         flex.appendChild(multi);
         flex.appendChild(spd);
    
    
     }
     else {alert("limit reached");}
    
    
    
  }
  

  function handlesubmit(e){
    e.preventDefault();
    Setformerror(validate(FormD,e));
    if(FormD.pname && FormD.pprice2 && FormD.pcategory && FormD.pdesc && FormD.pimage){
    setIsSubmit(true);
    }
    
  }
  useEffect(()=>{
    if(Object.keys(Formerror).length === 0 && isSubmit){
        
        insert_product();
    }
  },[Formerror]);
  
  const validate = (values,e)=>{
     const errors = {};
     
     const regex= "^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9.-]+$";
     if(!values.pname){
        errors.pname="Product Name Required!";
     }
     if(!values.pprice2){
        errors.pprice2 = "Product Price Required";
     }
     if(!values.pcategory){
        errors.pcategory = "Product Category Required";
     }
     if(!values.pimage){
        errors.pimage = "Product Image Required";
     }
     
     if(!values.pdesc){
        errors.pdesc = "Product Category Required";
     }
     return errors;
     }


  

   function handleform(e){
    console.log(FormD);
    SetFormD({...FormD,[e.target.name]:e.target.value});
    
   // SetValidation({...Validation,[e.target.name]:e.target.value});
    //console.log(Validation); 

   }
    return(
    <section id="content-section">
    <div className="col-sm-12 col-md-12 col-lg-12 content-box">
       
     <form method="post" onChange={(e)=>handleform(e)} onSubmit={(e)=>handlesubmit(e)} encType="multipart/form-data" id="formed">

      <div className="form-box">
       <div className="form-heading">
           <h6>Product-Name</h6>
       </div>
       <div className="form-input">
           <input type="text" name="pname" className="form-control"/>
           <span className="form_error">{Formerror.pname}</span>
       </div>
      </div>
      
      <div className="form-box">
      <div className="form-heading">
           <h6>Product-Image</h6>
       </div>
       <div className="form-input" id="image-box" >
           <div>
           <span><i className="fa fa-plus" onClick={(e)=>add_image(e)}/>
           <input type="file"  name="pimage"  id="first_file" accept="image/*"/></span>
           <span className="form_error">{Formerror.pimage}</span>
           </div>

       </div>
      </div>

      <div className="form-box">
      <div className="form-heading">
           <h6>Product-Price</h6>
       </div>
       <div className="form-input">
           <input type="number" className="form-control" name="pprice2"/>
           <span   className="form_error">{Formerror.pprice2}</span>
       </div>
      </div>

      <div className="form-box">
      <div className="form-heading">
           <h6>Category</h6>
       </div>

       <div className="form-input">
           <select className="form-control" name="pcategory">
           <option value="false">Select Category</option>
               <option value="Herb">Herb</option>
               <option value="cream">cream</option>
               <option value="faceial">faceial</option>
           </select>
           <span className="form_error">{Formerror.pcategory}</span>
       </div>
      </div>

      <div className="form-box-product">
      <div className="form-heading">
           <h6>Product-Description</h6>
       </div>
       <div className="form-input">
           <input type="text-area"  className="form-control" name="pdesc"/>
           <span   className="form_error">{Formerror.pdesc}</span>
       </div>
      </div>
     <div className="col-sm-12 col-md-12 col-lg-12 form-btns">
      <button type="submit" className="btn btn-primary form-btnss" >Save</button>
      
      </div>

      </form>
      </div>
   </section>
    );
}
export default Product;