import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Admin from './admin/Admin';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import ProductView from './admin/ProductView';
import Product from './admin/Product';
import UpdatePro from './admin/UpdatePro';
import AddHeader from './admin/category/AddHeader';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Admin />}></Route>
        <Route path="/admin/update" element={<UpdatePro />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
