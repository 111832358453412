import axios from "axios";
import { useEffect, useState } from "react";

function UpdatePro(){

    const [FormD,SetFormD]=useState([{
    pname:"",
    pprice:"",
    pcategory:"",
    pdesc:"",
    pimage:""


}]);
const [concerts, setConcerts] = useState(false);
const [ItemsData,SetItemData]=useState({});
const [Formerror,Setformerror]=useState({});
const [isSubmit,setIsSubmit]=useState(false);
const queryParams = new URLSearchParams(window.location.search);
const value = queryParams.get('id');
async function data_fetch(){
    await fetch("http://localhost/vishwas/backend/update_pro.php?id="+value)
    .then(res =>res.json())
    .then((result)=>{SetItemData(result)})
    setConcerts(true);
    }

useEffect(()=>{
   data_fetch();
},[]);





  function insert_product(){
      const form =document.getElementById('formed');
      const url="http://localhost/vishwas/backend/update_product.php?id="+value;
      let fData= new FormData(form);


  fData.append('product_name',FormD.pname);
   fData.append('product_image',fData.get('pimage'));
   fData.append('product_image2',fData.get('pimage2'));
   fData.append('product_image3',fData.get('pimage3'));
   fData.append('product_image4',fData.get('pimage4'));
   fData.append('product_price',FormD.pprice2);
   fData.append('product_cat',FormD.pcategory);
   fData.append('product_desc',FormD.pdesc);
   
   axios.post(url,fData, {
     headers: {
       "Content-Type": "multipart/form-data",
     },
   }).then(Response=>alert(Response.data)).catch(error=>alert(error));

  }

  let increment_perm=1;

  function deletes(e){
    const removed=document.getElementById(e.target.parentNode.id);
    if(increment_perm >= 0){
        increment_perm--;
    console.log("remove"+increment_perm);
    removed.remove();
    }
  }

  

  function add_image(){
        
    let select=document.getElementById('image-box');
    
    let multi=document.createElement('input');
    multi.type='File';
    multi.className='flex';
    
    
    
    let del=document.createElement('i');
    del.className='fa fa-remove';
    del.onclick=(e)=>deletes(e);


    let spd=document.createElement('span');
    spd.className='form_error';
    

    const flex=document.createElement('div');
    flex.className='flexship';
    flex.id='FileUplaod'+increment_perm;

     if(increment_perm <= 3){
        increment_perm++;
        multi.name='pimage'+increment_perm;
        spd.id='pimage_error'+increment_perm;
        //spd.innerHTML='{errors.pimage}'+increment_perm;
         select.appendChild(flex);
         flex.appendChild(del);
         flex.appendChild(multi);
         flex.appendChild(spd);
    
    
     }
     else {alert("limit reached");}
    
    
    
  }
  

  function handlesubmit(e){
    e.preventDefault();
    SetFormD({...FormD,
    pname:document.getElementById("pname").value,
    });
    Setformerror(validate(FormD,e));
    if(FormD.pname && FormD.pprice2 && FormD.pcategory && FormD.pdesc && FormD.pimage){
    setIsSubmit(true);
    }
    
  }
  useEffect(()=>{
    
    if(Object.keys(Formerror).length === 0 && isSubmit){
        insert_product();
    }
  },[Formerror]);
  
  const validate = (values,e)=>{
     const errors = {};
     
     const regex= "^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Z0-9.-]+$";
     if(!values.pname){
        errors.pname="Product Name Required!";
     }
     if(!values.pprice2){
        errors.pprice2 = "Product Price Required";
     }
     if(!values.pcategory){
        errors.pcategory = "Product Category Required";
     }
     if(!values.pimage){
        errors.pimage = "Product Image Required";
     }
     
     if(!values.pdesc){
        errors.pdesc = "Product Category Required";
     }
     return errors;
     }


  

   function handleform(e){
    console.log(FormD);
    SetFormD({...FormD,[e.target.name]:e.target.value});
    
   // SetValidation({...Validation,[e.target.name]:e.target.value});
    //console.log(Validation); 

   }

    return(
    <section id="content-section">
    {concerts === false?<div class="loader"></div>:
    
    <div className="col-sm-12 col-md-12 col-lg-12 content-box">
       
     <form method="post" onChange={(e)=>handleform(e)} onSubmit={(e)=>handlesubmit(e)} encType="multipart/form-data" id="formed">

      <div className="form-box">
       <div className="form-heading">
           <h6>Product-Name</h6>
       </div>
       <div className="form-input">
           <input type="text" name="pname" defaultValue={ItemsData[0].product_name} id="pname" className="form-control"/>
           <span className="form_error">{Formerror.pname}</span>
       </div>
      </div>
      
      <div className="form-box">
      <div className="form-heading">
           <h6>Product-Image</h6>
       </div>
       <div className="form-input" id="image-box" >
           <div>
           <span><i className="fa fa-plus" onClick={(e)=>add_image(e)}/>
           <input type="file"  name="pimage"  id="first_file" accept="image/*"/></span>
           <span className="form_error">{Formerror.pimage}</span>
           </div>

       </div>
      </div>

      <div className="form-box">
      <div className="form-heading">
           <h6>Product-Price</h6>
       </div>
       <div className="form-input">
           <input type="number" className="form-control" defaultValue={ItemsData[0].product_price} id="pprice"  name="pprice2"/>
           <span   className="form_error">{Formerror.pprice2}</span>
       </div>
      </div>

      <div className="form-box">
      <div className="form-heading">
           <h6>Category</h6>
       </div>

       <div className="form-input">
       
           <select className="form-control" name="pcategory" id="pcategory" >
           <option value="false">Select Category</option>
               <option value="Herb" >Herb</option>
               <option value="cream">cream</option>
               <option value="faceial">faceial</option>
           </select>
           <span className="form_error">{Formerror.pcategory}</span>
       </div>
      </div>

      <div className="form-box-product">
      <div className="form-heading">
           <h6>Product-Description</h6>
       </div>
       <div className="form-input">
           <input type="text-area"  className="form-control" defaultValue={ItemsData[0].product_desc}   name="pdesc"/>
           <span   className="form_error">{Formerror.pdesc}</span>
       </div>
      </div>
     <div className="col-sm-12 col-md-12 col-lg-12 form-btns">
      <button type="submit" className="btn btn-primary form-btnss" >Save</button>
      
      </div>

      </form>
      </div>}
    
   </section>
    );
}
export default UpdatePro;